.custom-div-width {
  width: 100%; /* Default width for mobile */
}
h1 {
  font-size: 1.5rem;
}

.carousel-image {
  height: 800px;
  object-fit: contain;
}
.navbar-bg-color {
  background-color: #002e5d;
}

.main-header {
  margin-top: 30px;
  padding-top: 30px;
}
.divider-span{
  width: 100%;
  height: 1px;
  background-color: #002e5d;
}

/* ISlamic Calender*/

.calendar {
  font-family: Arial, sans-serif;
}

.calendar-container{
  border: 1px solid #002e5d;
  background-color: gainsboro;
  border-radius: 10px;
  padding: 10px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.controls {
  margin-top: 10px;
}

.controls select,
.controls input,
.controls button {
  margin-right: 10px;
  padding: 5px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.day {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-align: center;
}

.current-date {
  background-color: #f0f0f0;
}

.special-days {
  border: 1px solid #002e5d;
  text-align: start;
  background-color: gainsboro;
  border-radius: 10px;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.special-days-txt {
  padding: 10px;
}

.special-day {
  background-color: #002e5d;
  color: white;
  margin: 10px auto;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  text-align: justify;
  display: flex;
  justify-content: space-between;
}

.heading-calendar {
  margin: 10px auto;
  padding: 10px;
  width: 100%;
  text-align: justify;
  display: flex;
  justify-content: space-between;
  font-size: 22px;
}

.special-day .sd-name {
  align-self: center;
}

.special-day .sd-geo-date {
  align-self: center;
}

.special-day .sd-hiz-name {
  align-self: center;
}

.weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.weekday {
  text-align: center;
  font-weight: bold;
  background-color: rgb(33, 36, 53);
  padding: 10px;
  margin-bottom: 10px;
  color: white;
}

.gregorian-date {
  font-weight: bold;
}

.custom-select {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
}

.custom-select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.custom-input {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
}

.custom-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.reset-btn {
  border: 0px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.reset-btn:hover {
  border: 0px;
  border-radius: 10px;
  background-color: #002f5da6;
  color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.hijri-date {
    font-size: 1.3em;
    color: green;
    font-weight: bold;
  }

/*MoreData*/
.download-container{
  text-align: end;
}

/*Detail Class Data*/
.table-img{
width: 100%;
height: auto;
}

/*Footer*/
.map-lyt {
  width: 300px;
  height: 200px;
  border: 1px solid black;
  align-items: center;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  background-color: #002e5d;
  padding: 30px;
  color: white;
}
.footer-container-main {
  text-align: center;
}
.box {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}
/* Gallery */
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}

.gallery-image {
  border-radius: 10px;
  width: 100%;
  height: auto;
  cursor: pointer;
}

/* Orientation-specific styles */
.gallery-image.landscape {
  grid-row: span 1;
}

.gallery-image.portrait {
  grid-row: span 3;
}

/* React Modal Custom Styles */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}




@media only screen and (min-width: 1024px) {
  /* Your CSS styles for desktops and laptops here */
  .custom-div-width {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Your CSS styles for tablets here */
  
}

@media only screen and (max-width: 767px) {
  /* Your CSS styles for mobile devices here */
  .device-txt{
    font-size: 0.7em;
  }
  .custom-div-width {
    width: 100%;
  }
  h1 {
    font-size: 12px;
  }
  .special-day {
    font-size: 14px;
  }
  .gregorian-date {
    font-size: 16px;
    font-weight: bold;
  }
  .hijri-day-name {
    font-size: 8px;
    padding: 0px;
    margin: 0px;
  }
  .hijri-date {
    font-size: 1.3em;
    color: green;
  }
  .calendar-grid {
    gap: 1px;
  }
  .main-header {
    margin-top: 30px;
    padding-top: 30px;
  }
  .gallery {
    grid-template-columns: repeat(3, 1fr); /* Two columns on mobile */
  }
  .gallery-image {
    border-radius: 5px;
  }
  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
  }
  .calender-download{
    font-size: 10px;
  }
  
}



body.modal-open .carousel-indicators,
body.modal-open .carousel-control-prev,
body.modal-open .carousel-control-next {
  display: none;
}




.language-label {
  display: inline-block;
  background-color: transparent;
  border: 1px solid #004085;
  color: #004085;
  font-size: 10px;
  padding: 5px 10px;
  font-family: Arial, sans-serif;
  border-radius: 20px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.language-label:hover {
  background-color: #0056b3;
  color: white;
}

input[type="radio"]:checked + label {
  background-color: #004085;
  color: white;

}
